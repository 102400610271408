@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

*{
	font-family: "Inter", sans-serif;
	font-size: 14px;
}

@media (min-width: 601px) {
	.login-container {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100vh;
		background-color: #F8F9FC;
	}

	.login-card {
		background: #ffffff;
		padding: 2em;
		border-radius: 8px;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
		text-align: center;
		width: 420px;
		font-family: 'Inter', sans-serif;
	}
}

@media (max-width: 600px) {
	.login-container {
		padding-top: 80px;
	}

	.login-card {
		background: #ffffff;
		padding: 1em;
		text-align: center;
		width: 100%;
		font-family: 'Inter', sans-serif;
	}
}

::-webkit-scrollbar{
	height: 8px;
    width: 8px;
	margin: 10px;
}

::-webkit-scrollbar-track, ::-webkit-scrollbar-track:horizontal{
	background:  #F0F0F2;
}

::-webkit-scrollbar-thumb, ::-webkit-scrollbar-thumb:horizontal{
	background: #BDBDBD;
	border-radius: 5px;
	
}

.login-card #btnLogin {
	width: 100%;
	border-radius: 50px;
	height: 50px;
}

.login-card #btnGoogle {
	width: 100%;
	border-radius: 50px;
	height: 50px;
}

.btn-primary {
	background-color: #3680F4 !important;
	border-color: #3680F4 !important;
	transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;
}

.btn-primary:focus {
	background-color: #0D72B5 !important;
	border-color: #0D72B5 !important;
}

.btn-primary:hover {
	background-color: #0D72B5 !important;
	border-color: #0D72B5 !important;
}

.link-primary {
	color: #3680F4 !important;
	text-decoration: none !important;
	transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;
}

.link-primary:focus {
	color: #0D72B5 !important;
}

.assitanceDrop:hover{
	color:  #0d6efd;
}

.link-primary:hover {
	color: #0D72B5 !important;
}

.btn-light {
	background-color: #F0F0F2 !important;
	border-color: #F0F0F2 !important;
	transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;
}

.btn-light:focus {
	background-color: #cdcdcd !important;
	border-color: #cdcdcd !important;
}

.btn-light:hover {
	background-color: #dadada !important;
	border-color: #dadada !important;
}

.btn-info {
	background-color: #EDCD1E !important;
	border-color: #EDCD1E !important;
	transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;
}

.btn-info:focus {
	background-color: #fae570 !important;
	border-color: #fae570 !important;
}

.btn-info:hover {
	background-color: #fae570 !important;
	border-color: #fae570 !important;
}

.link-info {
	color: #EDCD1E !important;
	text-decoration: none !important;
	transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;
}

.link-info:focus {
	color: #fae570 !important;
}

.link-info:hover {
	color: #fae570 !important;
}

.app {
    display: flex;
    height: 100vh;
    overflow: hidden;
	padding: 0 !important;
}

.dropArrow{
	margin-left: 10px;
	color: #4b4b4b;
	vertical-align: bottom;
	margin-bottom: 2px;
}

.navbar-vertical {
    width: 250px;
    height: 100vh;
    background-color: white;
	border-right: 2px solid #DFDFDF;
    position: fixed;
    overflow-y: auto;
	
}

.navbar-item a{
	text-decoration: none !important;
	color: black;
}

.navbar-item a:hover, .navbar-drop:hover, .navbar-drop:hover .dropArrow{
	color: #3680F4;
}

.navbar-icon-content {
	min-width: 20px;
}

.navbar-icon {
	background-color: #cfd4e3;
	border-radius: 5px;
	padding: 5px;
}

.navbar-menu-active{
	color: #3680F4 !important;	
	font-weight: 700;
}

.navbar-item:active{
	color: #3680F4;	
}

.content {
    flex-grow: 1;
    overflow-y: auto;
    height: 100vh;
	background: #F2F3F9;
}

.toolbar{
	background: white;
	border-radius: 50px;
	height: 50px;
	padding-left: 20px;
	padding-right: 20px;
	border: 1px solid #DFDFDF;
	box-shadow: 1px 3px 5px #e6e6e6 !important;
	display: flex;
    align-items: center;
    justify-content: center;
}

.cards {
	/* box-shadow: 1px 3px 5px #e6e6e6 !important; */
	border-radius: 15px !important;
	padding: 20px;
}

.cards.content {
	padding: 0 !important;
}

.content-table.head {
	text-align: center;
	border-bottom: 2px solid #A0A0A0;
	background-color: #EBEBEB;
	padding-top: 8px;
	padding-bottom: 8px;
	font-weight: bold;
}

.content-table.rows {
/* 	padding-top: 8px;
	padding-bottom: 8px; */
	border-bottom: 2px solid #EBEBEB;
}

.middle {
	text-align: center;
}

.content-table.rows.right {
	text-align: right;
}

.content-table.status {
	/* padding: 12px; */
	font-size: 1rem;
	font-weight: 400 !important;
}

.content-options.content {
	height: 72px;
	border-bottom: 3px solid #EBEBEB !important;
}

.content-options.rows.left {
	padding-left: 32px;
	width: 50%;
}

.content-options.rows.right {
	padding-right: 32px;
	text-align: right;
}

.column-title {
	background-color: #49A5DF;
	text-align: center;
	padding-top: 3px;
	padding-bottom: 3px;
	border-radius: 10px 10px 0px 0px;
	color: white
}

.column-content {
	background-color: #f9f9f9;
	padding: 16px;
	height: 100%;
}

.column-content-nh {
	background-color: #FAFAFA;
	padding: 16px;
}

.fc-col-header-cell-cushion {
	text-decoration: none !important;
}

.fc-daygrid-day-number {
	text-decoration: none !important;
}

/* .table-bordered>:not(caption)>*>* {
	border: none !important;
} */

.file.element {
	position: relative;
	/* display: flex; */
	/* align-items: center; */
	width: 100%;
	padding: 0.5rem 1.25rem;
	font-size: 1rem;
	color: #212529;
	text-align: left;
	background-color: #fff;
	border: 0;
	border-radius: 0;
	overflow-anchor: none;
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, .125);
	border-radius: 0.25rem;
}



.statusCard {
	border-right: none !important;
	border-top: none !important;
	border-bottom: none !important;
	height: 130px !important;
	box-shadow: 1px 3px 5px #CFCFCF !important;
}

.statusCard.green {
	border-left: 5px solid #28A745 !important;
}

.statusCard.yellow {
	border-left: 5px solid #FFC008 !important;
}

.statusCard.red {
	border-left: 5px solid #DC3545 !important;
}

.statusCard.administration {
	height: 108px !important;
}

.avatar-10 {
	width: 40px;
	height: 40px;
	border-radius: 50%;
}

.avatar-20 {
	width: 20px;
	height: 20px;
	border-radius: 50%;
}

.avatar-30 {
	width: 30px;
	height: 30px;
	border-radius: 50%;
}

.avatar-40 {
	width: 40px;
	height: 40px;
	border-radius: 50%;
}

.avatar-60 {
	width: 120px;
	height: 120px;
	border-radius: 50%;
}

.text-error {
	background: red !important;
	color: white !important;
}

.align-center-vertically {
	display: flex !important;
	align-items: center;
}

.align-center-vertically-v2 {
	margin-top: auto !important;
	margin-bottom: auto !important;
}

/*Home cards css*/

.home-mini-cards{
	max-height: 100%;
	padding: 10px 5px;
}

.card-icon-content{
	scale: 120%;
}

/* Button Style */

.edit-button{ 
	background-color: white;
	border: 2px solid #3680F4;
	border-radius: 30px;
	padding: 5px 15px;
	justify-self: end;
	margin-right: 5px;
	display: flex;
	align-items: center;
	text-decoration: none;
}

.edit-button:hover, .edit-button:hover .edit-icon-content, .edit-button:hover span{
	background-color: #3680F4;
	color:white;
	transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;
}

.carousel-img {
	height: 45vh;
}

.prev-next-icon{
	color: #BDBDBD;
	scale: 200%;
}

.smallFontSize {
	font-size: 11px;
	color: gray;
}

.interCards p {
	margin-bottom: 0px;
}

.course-col {
	min-height: 100%;
	max-height: 100%;
	border-radius: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 12% !important;
	padding: 0px 5px;
}

.gray-back{
	background-color: #BDBDBD;
}

.blue-back{
	background-color: #ABD5FF;
}

.blueTasky-back {
	background-color: #0480FD;
}
.whitedark-back{
	background-color: #F0F0F2;
}

.iconCol{
	max-width: 7% !important;
	padding: 0px !important;
	margin-right: 5px;
	align-self: center;
}

.slideContent {
	overflow-y: auto;
}

.calendarContainer{
	height: calc(55vh - 197px);
}

.interCards {
	margin-bottom: 5px;
}

.textNewsCont {
	max-height: calc(55vh - 257px);
	overflow: hidden;
	text-overflow: ellipsis;
}

.green{
	color:#8FDFBB;
}

.red{
	color:#FF9CA5;
}

.greenBack{
	background-color:#8FDFBB !important;
}

.redBack{
	background-color:#FF9CA5 !important;
}

.course-icon{
	scale: 115%;
}

.row {
	margin-right: 0px !important;
	margin-left: 0px !important;
}

.circle-container {
	width: 35px; 
	height: 35px;
	overflow: hidden; 
	border-radius: 50%; 
	background-color: #E5E6E9;
}
  
.studentImg{
	width: 30px;
	height: 30px;
	border: 2px solid #3f3f3f;
	display: flex;
	justify-content: center;
}

.circle-container img {
	height: 100%; 
	margin-left: -25%;
	display: block;
}

.circle-container p {
	color: #6D6D6D;
	text-align: center;
	margin-top: 5px;
}

.date{
	margin-bottom: 5px;
	margin-left: 10px;
	color: #6D6D6D;
}

.dots-icon{
	color:#737373;
	scale:120%;
}

.fitContentWidth {
	max-width: fit-content !important;
}

.toolBarIcon{
	margin-right: 15px;
}

.sideColor{
	width: 10px;
	height: 100%;
	position: absolute;
	left:0px;
	top:0px;
}

.campo-input{
	border-radius: 0.25rem;
	padding: 5px 5px;
	margin: 10px 0px; 
	border: 1px solid #6D6D6D !important;
	display: block;
	width: 100%;
	outline-style: none;
}

.calCreateButton{
	border-radius: 10px;
	border: 3px solid #F0F0F2;
	padding: 5px 10px;
}

.switchButton{
	border-radius: 10px;
	border: 3px solid #F0F0F2;
	padding: 5px 10px;
}

.calButtonCont{
	display: flex;
	background-color:#F0F0F2;
	border-radius: 10px;
	width: fit-content;
	flex-wrap: nowrap;
}

.switchButtonContainer{
	display: flex;
	background-color:#F0F0F2;
	border-radius: 10px;
	width: fit-content;
	flex-wrap: nowrap;
}

.modal-content{
	border-radius: 15px !important;
	border:0px !important;
}

.dropDownIcon{
	margin-right: 10px;
	font-size: 18px;
}

.modal-title {
	font-size: 20px !important;
}

.modal-body {
	border-bottom-left-radius: 15px !important;
	border-bottom-right-radius: 15px !important;
	padding: 20px 20px 10px 20px!important;
}

.modal-footer{
	background-color: white;
	border-bottom-right-radius: 15px !important;
	border-bottom-left-radius: 15px !important;
}

.modal-header{
	border-top-right-radius: 15px !important;
	border-top-left-radius: 15px !important;
}

.iconModalCol {
	max-width: 30px !important;
}
.iconModalCol svg{
	margin-top: 18px;
} 

.iconModaColSpecial svg{
	margin-top: 11px;
}

.remiderCross{
	top: 10px;
	color: grey;
}

.remiderCross:hover{
	color: #3680F4 ;
}

.endRow{
	display: flex;
	justify-content: flex-end;
}

.flexEnd{
	display: flex;
	align-items: flex-end;
}

.arrowStatics{
	scale:120%;
	padding: 0px 10px 10px
}

.marginSep{
	margin: 30px 0px !important;
}

.assistanceButton{
	border-radius: 5px;
	margin: 3px;
	color: black !important;
	border: none !important;
}

.assistGrey {
	background-color: white !important;
}

.staticCards{
	min-width: fit-content !important;
}

.wrapCont {
	flex-wrap: wrap;
}

.headerContainer th, .headerContainer td {
	align-self: center;
	text-align: center;
	vertical-align: middle;
	border: 1px solid white;
}

.whiteText {
	color: white;
}

.bigAssitButton{
	padding: 15px 25px !important;
}

.slideTable{
	width: 100%;
	overflow-x: auto;
	position: relative;
	height: 100%;
}

.sticky-row{
	position: sticky;
	top: -2px;
	z-index: 2;
	border: 1px solid white;
}

.sticky-column{
 	position: sticky;
	left: -2px;
	z-index: 1;
	border: 1px solid white;
}

.tableContainer{
	height: 60%;
}

.smallButton{
	width: 46px;
}

.navBarDrop{
	list-style: none;
	padding: 3px 0px 0px 10px;
}
.navBarDrop li{
	padding-bottom: 3px;
}

.dropDownIcon{
	width: 20px;
}

.dropDownCal {
	text-align: left !important;
	width: 200px;
}	

.dropdown-toggle::after {
	right: 0px;
}

.react-datepicker__input-container input {
	padding: 4px 12px;
}

.table {
	text-align: center;
}

.editGrades{
	background-color: #E5E6E9;
	color: #3680F4;
	display: flex;
	align-items: center;
	justify-content: center;
}

.configCards{
	padding: 20px 15px;
}

.iconConfig{
	margin-right: 10px;
}

.configLink{
	text-decoration: none !important;
	color: black;
}

.configLink:hover{
	color: rgb(43, 128, 255);
}

.react-datepicker__input-container{
	width: auto !important;
}

tbody{
	border-width: 0px 1px !important;
}

.framesContainer td{
	border-width: 0px 0px !important;
}

.frameDateContainer .react-datepicker__input-container {
	width: 100% !important;
}

.checkContainer{
	border: 2px solid #3680F4;
	border-radius: 30px;
	color:#3680F4;
	width: fit-content;
	padding: 2px 10px;
}

.checkContainer:hover{
	background-color:#3680F4;
	color: white;
}

.smallColTable{
	width: 15%;
}

.colorGray{
	color:#7d7d7d;
}

.personSanction{
	border-radius: 20px;
	padding: 1px 15px;
}

.ml-1{
	margin-left: 10px;
}

.textAreaSanction{
	height: 250px;
	border-radius: 0.25rem;
	border: 1px solid #6D6D6D !important;
}

.roundedText{
	border-radius: 20px;
	padding: 4px 20px;
}

.roundedText-v2{
	border-radius: 20px;
	padding: 2px 10px;
}

.justify-content-center{
	display: flex;
	justify-content: center;
}

.space-around{
	display: flex;
	justify-content: space-around;
}

.mr-10{
	margin-right: 10px;
}

.verticalAlignMiddle{
	vertical-align: middle;
}

.width-max-content{
	width: max-content;
}

td{
	vertical-align: middle;
}

.infoStudentContainer{
	border-radius: 15px;
	border: 3px solid #F0F0F2;
	display: flex;
	width: max-content;
}

.infoStudentContainer span{
	padding: 0px 15px;
}

.iconStudent{
	background-color: #F0F0F2;
	border-radius: 10px;
}

.iconStudent svg{
	padding: 1px 12px;
}

.dropDownCal input{
	border-width: 0px;
}

.dropDownCal input:focus{
	border-width: 0px;
	outline: 0px;
}

.dropDownCal .btn-primary:hover {
    background-color: white!important;
    border-color: white !important;
} 

.colorDiv{
	width: 9rem;
	min-width: 10px;
}

.headerBlue tr td{
	background-color: #3680F4 !important ;
}

.fs-8{
	font-size: 8px !important;
}

.fs-11{
	font-size: 11px !important;
}

.fs-14{
	font-size: 14px !important;
}

.fs-16{
	font-size: 16px !important;
}

.fs-18{
	font-size: 18px !important;
}

.fs-38{
	font-size: 38px !important;
}

.fw-700{
	font-weight: 700 !important;
}

.textInputGray{
	border: 1px solid #6D6D6D !important;
}

.h-75{
	height: 75%;
}

.tableRows{
	background: #F0F0F0;
	height: 43px;
}

.statusBadge{
	padding: 12px !important;
  	font-weight: 400 !important;
	font-size: 14px;
}

.dropdownToggle{
	height: 35px;
}

.br-15{
	border-radius: 15px;
}

.dropdownButton .dropdown-toggle:hover ,.dropdownButton .btn-primary:focus, .dropdownButton .dropdown-toggle, .dropdownButton .btn-primary {
	background-color: #ffffff !important;
	color: #000000 !important;
	border: 1px solid #6C757D !important;
	box-shadow: none !important;
}

.dropdownFilter.dropdown-toggle::after{
	position: absolute !important;
	right: 15px !important;
	top: 15px !important;
}

.wi-300{
	width: 300px !important;
}

.wi-200{
	width: 200px !important;
}

.wi-146{
	width: 146px !important;
}

.wi-147{
	width: 147px !important;
}

.wi-150{
	width: 150px !important;
}

.wi-120{
	width: 120px !important;
}

.wi-100{
	width: 100px !important;
}

.txt-left{
	text-align: left !important;
}

.input-text{
	background-color: #ffffff !important;
	color: #000000 !important;
	border: 1px solid #6C757D !important;
	box-shadow: none !important;
	border-radius: 0.25rem;
}

.carousel-control-next{
	justify-content: flex-end !important;
	margin-right: 20px;
}

.carousel-control-prev{
	justify-content: flex-start !important;
	margin-left: 20px;
}

.carousel-indicators{
	margin-bottom: 0px !important;
}

.notFoundIcon {
	scale: 200%;
	max-width: fit-content!important;
}

.notFoundContainer {
	min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
	color: gray;
}

.overflow-hidden{
	overflow: hidden;
}

.greyBackCard {
	background-color: rgb(240, 240, 240) !important;
	border:none !important;
}

.p-action-plus-button{
	padding: 5px 0px 5px 10px !important;
}

.borderDiv{
	border: 1px solid rgba(0, 0, 0, 0.125);
	background-color: rgb(240, 240, 240);
}

.studentLink:hover {
	color: #0480FD;
}

.link-no-deco{
	text-decoration: none;
	color:#000000;
}

.link-no-hover:hover {
	color:black;
}

.link-no-hover:active{
	color: white;
}

.studentCardHeight{
	height: 40.3vh;
}

.studentCardContainer{
	height: calc(41vh - 112px);
}

.table > :not(:last-child) > :last-child > * {
    border-bottom-color: rgb(222, 226, 230) !important;
} 

.borderLR {
	border-left-width: 1px;
	border-right-width: 1px;
}

.scheduleTable tr td{
	padding: 5px;
}

.dotsRow:active {
	color:white !important;
}

.entireRow{
	border-radius: 8px;
	background: #F0F0F0;
}

.form-control-color{
	max-width: 300px !important;
	height: 11px !important;
}